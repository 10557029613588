import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import {message, Tag} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import SelectCompanyWidget from '../../Components/SelectCompanyWidget';
import jobRequestJson from '../../../content/official/job-request.json';
import roomRequestJson from '../../../content/official/room-request.json';
import companyRequestJson from '../../../content/official/company-request.json';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';

const TYPES = {
  job: {
    title: '求才刊登申請',
    json: jobRequestJson,
  },
  room: {
    title: '辦公室刊登申請',
    json: roomRequestJson,
  },
  company: {
    title: '事務所變更申請',
    json: companyRequestJson,
  },
};

function insertReadOnlyProperty(schema, readOnly) {
  const _schema = {...schema};
  const propertiesKeys = Object.keys(_schema.properties);

  for (const key of propertiesKeys) {
    _schema.properties[key].readOnly = readOnly;
  }

  return _schema;
}

function insertProperty({type, record, schema, user}) {
  const _schema = {...schema};
  const propertiesKeys = Object.keys(_schema.properties);

  if (record) {
    for (const key of propertiesKeys) {
      _schema.properties[key].readOnly = true;
    }
  } else {
    for (const key of propertiesKeys) {
      _schema.properties[key].readOnly = false;
    }

    if (type === 'company') {
      _schema.properties['applicant'].default = user?.data?.name;
      _schema.properties['applicant'].readOnly = true;
    }
  }

  return _schema;
}

function RequestDetail(props) {
  const {type} = props.pageContext;
  const params = qs.parse(props.location.search);
  const {id} = params;

  const [user] = useOutlet('user');

  const [record, setRecord] = React.useState(null);
  const [count, setCount] = React.useState(0);

  const schema = insertProperty({
    type,
    record,
    schema: TYPES[type].json.schema,
    user,
  });

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);

      try {
        let resp = await JStorage.fetchOneDocument('review_request', {
          id,
          user: user.sub,
        });

        setRecord({...resp, ...resp.data});
        setCount(count + 1);

        AppActions.setLoading(false);
      } catch (err) {
        console.warn(err);
        AppActions.setLoading(false);
      }
    }

    if (id) {
      fetchData();
    }
  }, [id, user, type]);

  const widgets = {
    'select-company-widget': (props) => <SelectCompanyWidget {...props} />,
  };

  return (
    <Wrapper>
      <div className="container">
        <h1>{TYPES[type].title}</h1>

        {type === 'job' && (
          <p className="description">
            注意事項：本公會會員欲於本會網站刊登求才訊息，請直接填妥此申請表再送出，並請注意刊登內容，勿觸律師法及律師倫理規範。網站登載內容將依申請書內容上傳。刊登期限一個月。請注意，有
            *記號之欄位 資料為必填，填載完竣方能送出！
          </p>
        )}
        {type === 'room' && (
          <p className="description">
            注意事項：本公會會員欲於本會網站刊登辦公室出租分租等訊息，請直接填妥此申請表再送出，並請注意刊登內容，勿觸律師法及律師倫理規範。網站登載內容將依申請書內容上傳，如亦需張貼於會館、各律師休息室公布欄，請將布告內容另以A4規格紙張填載，傳真至(02)2391-3844，經審閱後予以張貼。所刊登資料本會將每個月定期更新一次。請注意，
            *部分欄位 之資料必須填寫！
          </p>
        )}

        {record && (
          <div className="review-container">
            <h2 className="title">審核詳情</h2>
            <div className="status">
              狀態：
              {(() => {
                switch (record['status']) {
                  case 'pending':
                    return <Tag>待處理</Tag>;
                  case 'processing':
                    return <Tag>處理中</Tag>;
                  case 'success':
                    return <Tag>審核通過</Tag>;
                  case 'cancel':
                    return <Tag>審核退件</Tag>;
                  case 'posted':
                    return <Tag>已刊登</Tag>;
                  case 'revoke':
                    return <Tag>已下架</Tag>;
                  case 'changed':
                    return <Tag>已變更</Tag>;
                  default:
                    return '';
                }
              })()}
            </div>
            <div className="note">審核備註：{record.review_note}</div>
          </div>
        )}

        <GenericForm
          key={count}
          instance={record}
          rjsfProps={{widgets}}
          schema={schema}
          uiSchema={TYPES[type].json.uiSchema}
          renderCustomSubmitButton={record ? null : undefined}
          onSubmit={async (formData, extValues) => {
            console.log('formData', formData);
            AppActions.setLoading(true);

            try {
              await JStorage.createDocument('review_request', {
                user: user.data.owner,
                applicant: formData.applicant || user.data.name,
                type,
                status: 'pending',
                data: {...formData},
              });

              message.success('申請成功。');

              AppActions.setLoading(false);
              AppActions.navigate(`/${type}-requests`);
            } catch (err) {
              console.warn(err);
              AppActions.setLoading(false);
            }
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 20px;

  display: flex;
  justify-content: center;

  & > .container {
    max-width: 840px;
    width: 100%;
    margin: 30px auto;

    & > .title-container {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > .review-container {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 20px;
      background-color: #f3f3f3;

      & > .title {
        margin-bottom: 5px;
      }

      & > div.status,
      div.note {
        margin-bottom: 5px;
        font-size: 1rem;
      }
    }

    & > .description {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 1rem;
      line-height: 2rem;
    }
  }
`;

export default withPageEntry(RequestDetail);
